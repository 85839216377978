import React from 'react'
// import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'

import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

// import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
// import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Chip from '@material-ui/core/Chip'
import FaceIcon from '@material-ui/icons/Face'
// import Icon from '@material-ui/core/Icon'

import ReportHistory from './student/ReportHistory'
import { renderGrade, renderMode, renderChoiceLevel } from './utils/try-convert'

import WeeklyCheck from './student/WeeklyCheck'
import Basic from './student/Basic'
import EntranceExam from './student/EntranceExam'
import RegularExam from './student/RegularExam'
import Diagnosis from './student/Diagnosis'

// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
// import Paper from '@material-ui/core/Paper'

// import VenueTabs from './venue/Tabs'

function TabContainer (props) {
  return (
    <Typography component="div" style={{ marginTop: 16 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     marginTop: theme.spacing(3),
//     overflowX: 'auto'
//   },
//   table: {
//     minWidth: 650
//   }
// }))


const Student = ({ data: { loading, error, store } }) => {
  // const classes = useStyles()
  const [value, setValue] = React.useState(0)

  function handleChange (event, newValue) {
    setValue(newValue)
  }

  console.log(store)

  if (error) return <p>エラーが発生しました。システム管理者にお問い合せください。</p>
  if (!loading) {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7} md={8} lg={9}>
            <Box mt={2}>
              <Typography variant="h4" component="h1" gutterBottom>
                {store.user.lastName} {store.user.firstName} さん <Chip icon={<FaceIcon />} label={renderMode(store.user.midSchDetail.currentModeId)} color="primary" size="small" />
              </Typography>

              <p>最終アクセス日時:&nbsp;{format(store.user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}　最終報告日:&nbsp;--- </p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={3} justify="flex-end" alignItems="flex-end">
            <Box mt={2}>
              <ReportHistory answers={store.user.midSchMonthlyAnswers} code={store.code} id={store.user.id} />&nbsp;
              {/* <Button variant="outlined" href={`/qrview/${store.code}/${store.user.userCode}/${encodeURIComponent(store.user.firstName)}/${encodeURIComponent(store.user.lastName)}/${store.user.authToken}`} target="_brank">
                <Icon>crop_free</Icon>&nbsp;QRコード
              </Button> */}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" gutterBottom>
              生徒情報
            </Typography>
            <ul>
              <li>学校:&nbsp;{store.user.schoolName}</li>
              <li>学年:&nbsp;{renderGrade(store.user.gradeCode)}</li>
              <li>志望校:&nbsp;
                {store.user.choiceHighSch ? `${store.user.choiceHighSch.choiceHighSch.name}`: ''}
                {store.user.midSchDetail.simpleChoiceDeviationValue ? `${renderChoiceLevel(store.user.midSchDetail.simpleChoiceDeviationValue)}`: ''}
                {!store.user.choiceHighSch ? '' : ''}
              </li>
              <li>所属店舗:&nbsp;{store.name}</li>
              <li>会員種別: &nbsp;{store.user.tabletOnly}</li>
              <li>担当者:&nbsp;{store.user.staffName}</li>
              <li>サービス利用開始日:&nbsp;{format(store.user.startedAt, 'YYYY年 MMMDo', {locale: ja})}</li>
              <li>生徒コード:&nbsp;{store.user.userCode}</li>
            </ul>
          </Grid>
        </Grid>
        <Box my={10}>
          <AppBar position="static" color="#FFF">
            <Tabs variant="fullWidth" value={value} onChange={handleChange}>
              <Tab
                label="毎週チェック"
                indicatorColor="primary"
                textColor="primary" />
              <Tab
                label="基礎固め"
                indicatorColor="primary"
                textColor="primary" />
              <Tab
                label="入試対策"
                indicatorColor="primary"
                textColor="primary" />
              <Tab
                label="定期テスト対策"
                indicatorColor="primary"
                textColor="primary" />
              <Tab
                label="学習診断"
                indicatorColor="primary"
                textColor="primary" />
            </Tabs>
          </AppBar>
          {value === 0 &&
            <TabContainer>
              <WeeklyCheck code={store.code} id={store.user.id} />
            </TabContainer>}
          {value === 1 &&
            <TabContainer>
              <Basic code={store.code} id={store.user.id} />
            </TabContainer>}
          {value === 2 &&
            <TabContainer>
              <EntranceExam code={store.code} id={store.user.id} />
            </TabContainer>}
          {value === 3 &&
            <TabContainer>
              <RegularExam code={store.code} id={store.user.id} />
            </TabContainer>}
          {value === 4 &&
            <TabContainer>
              <Diagnosis code={store.code} id={store.user.id} />
            </TabContainer>}
        </Box>
      </>
    )
  }
  return <p>生徒情報を取得中...</p>
}

Student.propTypes = {
  userCode: PropTypes.number,
  code: PropTypes.number,
  id: PropTypes.number,
}

export const StudentQuery = gql`
  query store($code: ID!, $id: ID!) {
    store(code: $code){
      code
      name
      user(id: $id) {
        # authToken
        id
        userCode
        firstName
        lastName
        startedAt
        lastAccessedAt
        schoolName
        gradeCode
        staffName
        tabletOnly
        midSchDetail {
          currentModeId
          simpleChoiceDeviationValue
        }
        choiceHighSch {
          choiceHighSch {
            name
          }
        }
        midSchMonthlyAnswers {
          targetYearMonth
          schoolDivisionCode
          updatedAt
        }
      }
    }
  }
`

export default graphql(StudentQuery, {
  options: ({ match }) => ({
    variables: {
      code: match.params.code,
      id: match.params.id
    }
  })
})(Student)

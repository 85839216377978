import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'

import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

// import Button from '@material-ui/core/Button'

// import { renderMidSubject } from '../utils/try-convert'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import DiagnosisButton from './DiagnosisButton'
import PrintButton from '../utils/print'


const GET_DIAGNOSIS = gql`
  query store($code: ID!, $id: ID!) {
    store(code: $code){
      code
      user(id: $id) {
        id
        midSchQuizResults {
          id
          updatedAt
          midSchNormalSubjectId
          goodUnitCount
          totalUnitCount
        }
      }
    }
  }
`

class Diagnosis extends Component {
  state = {
    store: [],
    code: this.props.code,
    id: this.props.id,
    subject: []
  }

  render () {
    const { code, id } = this.props

    return (
      <Query query={GET_DIAGNOSIS} variables={{ code, id }}>
        {({ data, loading, error }) => {
          if (loading) return <p>診断テスト結果を取得中...</p>
          if (error) return <p>エラーが発生しました。システム管理者にお問い合せください。</p>

          return (
            <>
              <PrintButton />
              <Box mt={5} mx={2}>
                <Typography variant="h6" component="h1" gutterBottom>
                  英語
                </Typography>
              </Box>
              <Box my={1} mx={2}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">学習診断実施日</TableCell>
                        <TableCell align="right">学習診断結果のダウンロード</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.store.user.midSchQuizResults.filter(x => x.midSchNormalSubjectId === '1').map(result => (
                        <TableRow key={result.id} >
                          <TableCell component="th" scope="row">{format(result.updatedAt, 'YYYY年MMMDo HH時mm分', {locale: ja})}</TableCell>
                          <TableCell align="right">
                            <DiagnosisButton id={result.id} good={result.goodUnitCount} total={result.totalUnitCount} user={data.store.user.id} code={data.store.code} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
              <Box mt={5} mx={2}>
                <Typography variant="h6" component="h1" gutterBottom>
                  数学
                </Typography>
              </Box>
              <Box my={1} mx={2}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">学習診断実施日</TableCell>
                        <TableCell align="right">学習診断結果のダウンロード</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.store.user.midSchQuizResults.filter(x => x.midSchNormalSubjectId === '2').map(result => (
                        <TableRow key={result.id} >
                          <TableCell component="th" scope="row">{format(result.updatedAt, 'YYYY年MMMDo HH時mm分', {locale: ja})}</TableCell>
                          <TableCell align="right">
                            <DiagnosisButton id={result.id} good={result.goodUnitCount} total={result.totalUnitCount} user={data.store.user.id} code={data.store.code} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
              <Box mt={5} mx={2}>
                <Typography variant="h6" component="h1" gutterBottom>
                  理科
                </Typography>
              </Box>
              <Box my={1} mx={2}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">学習診断実施日</TableCell>
                        <TableCell align="right">学習診断結果のダウンロード</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.store.user.midSchQuizResults.filter(x => x.midSchNormalSubjectId === '3').map(result => (
                        <TableRow key={result.id} >
                          <TableCell component="th" scope="row">{format(result.updatedAt, 'YYYY年MMMDo HH時mm分', {locale: ja})}</TableCell>
                          <TableCell align="right">
                            <DiagnosisButton id={result.id} good={result.goodUnitCount} total={result.totalUnitCount} user={data.store.user.id} code={data.store.code} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
              <Box mt={5} mx={2}>
                <Typography variant="h6" component="h1" gutterBottom>
                  地理
                </Typography>
              </Box>
              <Box my={1} mx={2}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">学習診断実施日</TableCell>
                        <TableCell align="right">学習診断結果のダウンロード</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.store.user.midSchQuizResults.filter(x => x.midSchNormalSubjectId === '4').map(result => (
                        <TableRow key={result.id} >
                          <TableCell component="th" scope="row">{format(result.updatedAt, 'YYYY年MMMDo HH時mm分', {locale: ja})}</TableCell>
                          <TableCell align="right">
                            <DiagnosisButton id={result.id} good={result.goodUnitCount} total={result.totalUnitCount} user={data.store.user.id} code={data.store.code} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
              <Box mt={5} mx={2}>
                <Typography variant="h6" component="h1" gutterBottom>
                  歴史
                </Typography>
              </Box>
              <Box my={1} mx={2}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">学習診断実施日</TableCell>
                        <TableCell align="right">学習診断結果のダウンロード</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.store.user.midSchQuizResults.filter(x => x.midSchNormalSubjectId === '5').map(result => (
                        <TableRow key={result.id} >
                          <TableCell component="th" scope="row">{format(result.updatedAt, 'YYYY年MMMDo HH時mm分', {locale: ja})}</TableCell>
                          <TableCell align="right">
                            <DiagnosisButton id={result.id} good={result.goodUnitCount} total={result.totalUnitCount} user={data.store.user.id} code={data.store.code} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
              <Box mt={5} mx={2}>
                <Typography variant="h6" component="h1" gutterBottom>
                  公民
                </Typography>
              </Box>
              <Box my={1} mx={2}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">学習診断実施日</TableCell>
                        <TableCell align="right">学習診断結果のダウンロード</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.store.user.midSchQuizResults.filter(x => x.midSchNormalSubjectId === '6').map(result => (
                        <TableRow key={result.id} >
                          <TableCell component="th" scope="row">{format(result.updatedAt, 'YYYY年MMMDo HH時mm分', {locale: ja})}</TableCell>
                          <TableCell align="right">
                            <DiagnosisButton id={result.id} good={result.goodUnitCount} total={result.totalUnitCount} user={data.store.user.id} code={data.store.code} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
            </>
          )
        }}
      </Query>
    )
  }
}

Diagnosis.propTypes = {
  id: PropTypes.number.isRequired,
  user: PropTypes.number.isRequired,
  code: PropTypes.number.isRequired,
}

export default Diagnosis

import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'

import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import UserSearch from './venue/UserSearch'
import { renderGrade } from './utils/try-convert'
import JsHsButton from '../components/utils/js-hs'
// import QrPrintButton from './utils/qr-print'

function TabContainer (props) {
  return (
    <Typography component="div" style={{ marginTop: 16 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 650,
    width: '100%',
    whiteSpace: 'nowrap'
  },
  tablePadding: {
    paddingRight: 5
  }
}))

const Venue = ({ data: { loading, error, store } }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  function handleChange (event, newValue) {
    setValue(newValue)
  }

  if (error) return <p>エラーが発生しました。システム管理者にお問い合せください。</p>
  if (!loading) {

    const targetUsers = store.incompletedRegularExams.filter(x => x.usersCount !== 0)

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box my={2}>
              <Typography variant="h5" component="h1" gutterBottom>
                {store.name}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                店舗コード: {store.code}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Box mt={2} align="right">
              <JsHsButton officeCode={store.code} />
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3} lg={3}>
            <Box mt={2}>
              <QrPrintButton officeCode={store.code} officeName={store.name} />
            </Box>
          </Grid> */}
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" gutterBottom>
              利用状況
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              本会員
            </Typography>
            <Typography>
              {!store.adminFirstViewItems.regularMemberKpi || !store.adminFirstViewItems.regularMemberKpi.startedAt ? '--' : `${format(store.adminFirstViewItems.regularMemberKpi.startedAt, 'YYYY年 MMMDo', {locale: ja})}` }
              　〜　
              {!store.adminFirstViewItems.regularMemberKpi || !store.adminFirstViewItems.regularMemberKpi.endedAt ? '--' : `${format(store.adminFirstViewItems.regularMemberKpi.endedAt, 'YYYY年 MMMDo', {locale: ja})}` }
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h3" display="inline">
              {!store.adminFirstViewItems.regularMemberKpi || !store.adminFirstViewItems.regularMemberKpi.ratio ? '0' : `${store.adminFirstViewItems.regularMemberKpi.ratio}` }
            </Typography>
            <Typography display="inline">
              &nbsp;%／週
            </Typography>
          </Grid>
        </Grid>
        <Box my={10}>
          <AppBar position="static" color="#FFF">
            <Tabs variant="fullWidth" value={value} onChange={handleChange}>
              <Tab
                label="要対応"
                indicatorcolor="primary"
                textColor="primary" />
              <Tab
                label="生徒の絞り込み"
                indicatorcolor="primary"
                textColor="primary" />
            </Tabs>
          </AppBar>
          {value === 0 &&
            <TabContainer>
              <Box my={5} mx={2}>
                <Typography variant="h6" gutterBottom>
                  セットアップ
                </Typography>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className={classes.tablePadding}>生徒氏名</TableCell>
                        <TableCell align="left" className={classes.tablePadding}>生徒コード</TableCell>
                        <TableCell align="left" className={classes.tablePadding}>学年</TableCell>
                        <TableCell align="left" className={classes.tablePadding}>担当</TableCell>
                        <TableCell align="left" className={classes.tablePadding}>契約日</TableCell>
                        <TableCell align="left" className={classes.tablePadding}>対応</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {store.requiredSetupUsers.map(user => (
                        <TableRow key={user.userCode}>
                          <TableCell component="th" scope="row" className={classes.elipsis}>{user.lastName} {user.firstName}</TableCell>
                          <TableCell align="left" className={classes.tablePadding}>{user.userCode}</TableCell>
                          <TableCell align="left" className={classes.tablePadding}>{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left" className={classes.tablePadding}>{user.staffName}</TableCell>
                          <TableCell align="left" className={classes.tablePadding}>{format(user.contractDate, 'YYYY年 MMMDo', {locale: ja})}</TableCell>
                          <TableCell align="left" className={classes.tablePadding}>{user.instruction}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>

              <Box my={5} mx={2}>
                <Typography variant="h6" gutterBottom>
                  定期テスト情報
                </Typography>
                <p><strong>【期間】</strong></p>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 1).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>一学期中間テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 1).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 2).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>一学期期末テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 2).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 3).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography>二学期中間テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 3).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 4).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    <Typography>二学期期末テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 4).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 5).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5-content"
                    id="panel5-header"
                  >
                    <Typography>三学期期末テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 5).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 11).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6-content"
                    id="panel6-header"
                  >
                    <Typography>前期中間テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 11).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 12).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7-content"
                    id="panel7-header"
                  >
                    <Typography>前期期末テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 12).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 13).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel8-content"
                    id="panel8-header"
                  >
                    <Typography>後期中間テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 13).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 14).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel9-content"
                    id="panel9-header"
                  >
                    <Typography>後期期末テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 1).filter(y => y.regularExamLabelCode === 14).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <p><strong>【結果】</strong></p>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 1).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel11-content"
                    id="panel11-header"
                  >
                    <Typography>一学期中間テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 1).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 2).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel12-content"
                    id="panel12-header"
                  >
                    <Typography>一学期期末テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 2).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 3).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel13-content"
                    id="panel13-header"
                  >
                    <Typography>二学期中間テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 3).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 4).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel14-content"
                    id="panel14-header"
                  >
                    <Typography>二学期期末テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 4).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 5).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel15-content"
                    id="panel15-header"
                  >
                    <Typography>三学期期末テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 5).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 11).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel16-content"
                    id="panel16-header"
                  >
                    <Typography>前期中間テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 11).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 12).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel17-content"
                    id="panel17-header"
                  >
                    <Typography>前期期末テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 12).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 13).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel18-content"
                    id="panel18-header"
                  >
                    <Typography>後期中間テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 13).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled={targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 14).length ? false : true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel19-content"
                    id="panel19-header"
                  >
                    <Typography>後期期末テスト</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">生徒氏名</TableCell>
                          <TableCell align="left">生徒コード</TableCell>
                          <TableCell align="left">学校名</TableCell>
                          <TableCell align="left">学年</TableCell>
                          <TableCell align="left">担当</TableCell>
                          <TableCell align="left">会員種別</TableCell>
                          <TableCell align="left">最終アクセス日時</TableCell>
                        </TableRow>
                      </TableHead>
                      {targetUsers.filter(x => x.target === 2).filter(y => y.regularExamLabelCode === 14).map(exam => (
                      <TableBody>
                        {exam.users.map(user => (
                        <TableRow key={user.userCode} size="small">
                          <TableCell align="left">
                            <Link to={`/student/${store.code}/${user.id}`} user={user}>{user.lastName} {user.firstName}</Link>
                          </TableCell>
                          <TableCell align="left">{user.userCode}</TableCell>
                          <TableCell align="left">{user.schoolName}</TableCell>
                          <TableCell align="left">{renderGrade(user.gradeCode)}</TableCell>
                          <TableCell align="left">{user.staffName}</TableCell>
                          <TableCell align="left">{user.tabletOnly}</TableCell>
                          <TableCell align="left">{format(user.lastAccessedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                      ))}
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Box>
            </TabContainer>}
          {value === 1 &&
            <TabContainer>
              <UserSearch storeCode={store.code} />
            </TabContainer>}
        </Box>
      </>
    )
  }
  return <p>店舗情報を取得中...</p>
}

Venue.propTypes = {
  userCode: PropTypes.number,
  code: PropTypes.number,
}

export const venueQuery = gql`
  query store($code: ID!) {
    store(code: $code){
      adminFirstViewItems {
        regularMemberKpi {
          ratio
          startedAt
          endedAt
        }
      }
      code
      name
      requiredSetupUsers {
        firstName
        lastName
        userCode
        schoolName
        gradeCode
        staffName
        contractDate
        instruction
      }
      incompletedRegularExams {
        regularExamLabelCode
        gradeCode
        target
        usersCount
        instruction
        users {
          id
          lastName
          firstName
          userCode
          schoolName
          gradeCode
          staffName
          tabletOnly
          lastAccessedAt
        }
      }
    }
  }
`

export default graphql(venueQuery, {
  options: ({ match }) => ({
    variables: {
      code: match.params.code
    }
  })
})(Venue)

import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import Header from './Header'
import HeaderLogout from './HeaderLogout'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Login from './Login'
import VenueSearch from './VenueSearch'
import Venue from './Venue'
import Student from './Student'
import Reports from './student/Reports'

import QRView from './QRView'
import QRVenue from './QR-Venue'

const LOGIN_USER = gql`
  query{
    me {
      staffName
      stores {
        name
        code
      }
    }
  }
`

// const isLoggedIn = localStorage.getItem('isLoggedIn')
// const storeCode = localStorage.getItem('storeCode')

// console.log(isLoggedIn - storeCode)

class App extends Component {
  state = {
    employeeName: ``,
    officeCode: ``,
    officeName: ``,
    error: '',
  }

  render() {
    return (
      <Query query={LOGIN_USER}>
        {({ data, loading, error }) => {
          if (loading) return <p>認証情報を確認中...</p>
          if (error) {
            return (
              <>
                <HeaderLogout />
                <Container>
                  <Box my={4}>
                    <Switch>
                      <Route exact path="/login" component={Login} />
                      <Redirect from="*" to='/login' />
                    </Switch>
                  </Box>
                </Container>
              </>
            )
          }

          console.log(data.me)
          localStorage.setItem('storeCode', `${data.me.stores[0].code}`)
          localStorage.setItem('storeName', `${data.me.stores[0].name}`)

          return (
            <>
              <Header loginUsers={data.me} />
              <Container>
                <Box my={4}>
                  <Switch>
                    <Route exact path="/">
                      <Redirect to='/search' />
                    </Route>
                    <Route exact path="/login">
                      <Redirect to='/search' />
                    </Route>
                    {/* <Route exact path="/login">
                      <Redirect to={`/venue/${data.me.stores[0].code}`} />
                    </Route> */}
                    <Route exact path="/search" component={VenueSearch} />
                    <Route exact path="/venue/:code" component={Venue} />
                    <Route exact path="/student/:code/:id" component={Student} />
                    <Route exact path="/student/:code/:id/reports" component={Reports} />
                    <Route exact path="/qr-venue/:officeCode/:officeName/:authToken" component={QRVenue} />
                    <Route exact path="/qrview/:code/:userCode/:firstName/:lastName/:authToken" component={QRView} />
                  </Switch>
                </Box>
              </Container>
            </>
          )
        }}
      </Query>
    )
  }
}

export default App

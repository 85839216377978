import React, { Component } from 'react'
import PropTypes from 'prop-types'

// import { Query } from 'react-apollo'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'

import Button from '@material-ui/core/Button'
// import CircularProgress from '@material-ui/core/CircularProgress'

const GET_FILE = gql`
  query store($code: ID!, $user: ID!, $id: ID!) {
    store(code: $code){
      code
      user(id: $user) {
        id
        midSchQuizResults {
          id
        }
        midSchQuizResultFileUrl (resultId: $id)
      }
    }
  }
`

class DiagnosisButton extends Component {
  state = {
    midSchQuizResultFileUrl: '',
    code: this.props.code,
    user: this.props.user,
    id: this.props.id,
    good: this.props.good,
    total: this.props.total,
    isLoading: false
  }

  render () {
    const { good, total } = this.props

    return (
      <>
        <Button size="small" variant="contained" color="primary" onClick={() => this._getFile()}>
          {good} / {total}
        </Button>
      </>
    )
  }

  _getFile = async () => {
    const { code, user, id } = this.state
    this.setState({ isLoading: true })
    const result = await this.props.client.query({
      query: GET_FILE,
      variables: { code, user, id },
    })
    const store = result.data.store
    console.log(store)
    this.setState({ isLoading: false })
    window.open(result.data.store.user.midSchQuizResultFileUrl)
  }
}

DiagnosisButton.propTypes = {
  id: PropTypes.number,
  user: PropTypes.number,
  code: PropTypes.number,
}

export default withApollo(DiagnosisButton)

import React from 'react'
import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Icon from '@material-ui/core/Icon'

export default function ScrollDialog(props) {

  const [open, setOpen] = React.useState(false)
  const [scroll, setScroll] = React.useState('paper')

  const handleClickOpen = scrollType => () => {
    setOpen(true)
    setScroll(scrollType)
  };

  function handleClose() {
    setOpen(false)
  }

  const histories = props
  const code = props.code
  const id = props.id
  // console.log(histories)
  // console.log(code, id)

  return (
    <>
      <Button onClick={handleClickOpen('paper')} variant="outlined" color="primary">
        <Icon>list_alt</Icon>&nbsp;報告履歴
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        fullWidth="true"
      >
        <DialogTitle id="scroll-dialog-title">毎月チェック履歴</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText>
            {histories.answers.map(answer => (
              <p><strong>{format(answer.updatedAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}:</strong> <a href={`/student/${code}/${id}/reports?target_year_month=${answer.targetYearMonth}&division_code=${answer.schoolDivisionCode}`} code={code} id={id}>毎月チェック</a>が入力されました。</p>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            　閉じる　
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

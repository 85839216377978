import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'

import { withStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
// import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'

const styles ={
  root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1
  }
}

class Header extends Component {
  render() {
    // const authToken = localStorage.getItem('isLoggedIn')
    // const userStore = localStorage.getItem('storeName')
    // const storeCode = localStorage.getItem('storeCode')

    console.log(this.props)
    const { staffName, stores } = this.props.loginUsers
    const store = stores[0]

    console.log(staffName)
    console.log(store)

    return (
      <div className={this.props.classes.root}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="h6" className={this.props.classes.title}>
              AI5教科管理 ―中学版―
            </Typography>
            {` `}
            {staffName && (
              <div
                onClick={() => {
                  this.props.history.push(`/search`)
                }}
              >
                　店舗検索　
              </div>
            )}
            {` `}
            {staffName && (
              <div
                onClick={() => {
                  this.props.history.push(`/venue/${store.code}`)
                }}
              >
                　{store.name}　
              </div>
            )}
            {` `}
            <div>
              <IconButton
                aria-label="Account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Header))

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import QRCode from 'qrcode.react';

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import PrintButton from './utils/print'

class QRVenue extends Component {
  state = {
    props: this.props,
  }

  render () {
    const { officeCode, officeName, authToken } = this.state.props.match.params

    return (
      <>
        <PrintButton />
        <Container maxWidth="sm">
          <Box my={4}>
          <Typography variant="h5" component="h1" gutterBottom>
            <span>店舗名: {decodeURIComponent(officeName)}</span>
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <span>店舗コード: {officeCode}</span>
          </Typography>
          </Box>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Box my={4}>
              <QRCode value={authToken} size="96" />
            </Box>
          </Grid>
        </Container>
      </>
    )
  }
}

QRVenue.propTypes = {
  officeCode: PropTypes.number.isRequired,
  officeName: PropTypes.string.isRequired,
}

export default QRVenue

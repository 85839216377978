import React, { Component } from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'

export const LOGIN_USER = gql`
  mutation adminLogin($staffCode: ID!, $password: String!) {
    adminUserLogin(staffCode: $staffCode, password: $password) {
      staffName
      stores {
        name
        code
      }
      errors {
        code
        messages
      }
    }
  }
`

class Login extends Component {
  state = {
    staffCode: ``,
    password: ``,
    error: '',
    activate: false
  }

  render () {
    return (
      <Container maxWidth="sm">
        <Typography variant="h6" component="h1" gutterBottom>
          管理画面ログイン
        </Typography>
        <p>スタッフコードとパスワードを入力してログインしてください。</p>
        <Box my={1}>
          <TextField
            type="number"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="staffCode"
            label="スタッフコード"
            name="staffCode"
            autoComplete="staffCode"
            autoFocus
            onChange={e => this.setState({ staffCode: e.target.value, error: '' })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => this.setState({ password: e.target.value, error: '', activate: true })}
          />
        </Box>
        <Box my={2}>
          <Mutation
            mutation={LOGIN_USER}
            variables={{ staffCode: this.state.staffCode, password: this.state.password }}
            onCompleted={data => this._confirm(data)}
          >
            {mutation => (
              <>
                <Button disabled = {this.state.activate ? false : true} variant="contained" color="primary" onClick={mutation}>
                  管理画面にログイン
                </Button>
                <p><small>{this.state.error}</small></p>
              </>
            )}
          </Mutation>
        </Box>
      </Container>
    )
  }

  _confirm = async data => {
    const errorCode = data.adminUserLogin.errors.code
    const storeName = data.adminUserLogin.stores.map(store => (store.name))
    const storeCode = data.adminUserLogin.stores.map(store => (store.code))
    console.log(errorCode)
    if (!errorCode) {
      localStorage.setItem('isLoggedIn', true)
      localStorage.setItem('storeName', storeName)
      localStorage.setItem('storeCode', storeCode)
      window.location.reload()
    } else {
      this.state.error = 'スタッフコード、またはパスワードを確認してください'
    }
  }
}

export default Login

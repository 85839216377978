export function renderGrade (gradeCode) {
  switch (gradeCode) {
    case '16': return '小6'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '21': return '中1'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '22': return '中2'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '23': return '中3'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '28': return '中卒'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '29': return '高校受験準備'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '--'
  }
}

export function renderMember (tabletOnly) {
  switch (tabletOnly) {
    case true: return 'タブレット会員'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case false: return '本会員'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '--'
  }
}

export function renderWeeklyStatus (examinationStatus) {
  switch (examinationStatus) {
    case 0: return '–'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 1: return '◯'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 2: return '◎'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '–'
  }
}

export function renderChoiceLevel (simpleChoiceDeviationValue) {
  switch (simpleChoiceDeviationValue) {
    case 50: return '〜中位校'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 55: return '中上位校'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 60: return '上位校'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 65: return '難関校'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable   no-unreachable */
    case 999: return '最難関校'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '--'
  }
}

export function renderMidSubject (subjectId) {
  switch (subjectId) {
    case '1': return '英語'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '2': return '数学'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '3': return '理科'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '4': return '地理'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '5': return '歴史'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '6': return '公民'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '7': return '国語'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '--'
  }
}

export function renderSubjectCategory (subjectId) {
  switch (subjectId) {
    case '1': return '英語'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '2': return '数学'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '3': return '国語'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '4': return '理科'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '5': return '社会'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '--'
  }
}

export function renderMode (currentModeId) {
  switch (currentModeId) {
    case 1: return '毎週チェック'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 2: return '基礎固め'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 3: return '定期テスト対策'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 4: return '修了テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 5: return '入試対策'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '--'
  }
}

export function renderRegularExam (regularExamLabelCode) {
  switch (regularExamLabelCode) {
    case 1: return '1学期 中間テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 2: return '1学期 期末テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 3: return '2学期 中間テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 4: return '2学期 期末テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 5: return '3学期 期末テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 11: return '前期 中間テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 12: return '前期 期末テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 13: return '後期 中間テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 14: return '後期 期末テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */

    default: return '--'
  }
}


import React, { Component } from 'react'
import PropTypes from 'prop-types'

// import { Query } from 'react-apollo'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'

import Button from '@material-ui/core/Button'
// import CircularProgress from '@material-ui/core/CircularProgress'

const GET_FILE = gql`
  query store($code: ID!, $user: ID!) {
    store(code: $code){
      code
      user(id: $user) {
        id
        midSchCompletionExamFileUrl
      }
    }
  }
`

class BasicButton extends Component {
  state = {
    midSchCompletionExamFileUrl: '',
    code: this.props.code,
    user: this.props.user,
    isLoading: false
  }

  render () {
    return (
      <>
        <Button size="small" variant="contained" color="primary" onClick={() => this._getFile()}>
          修了テスト結果のダウンロード
        </Button>
      </>
    )
  }

  _getFile = async () => {
    const { code, user } = this.state
    this.setState({ isLoading: true })
    const result = await this.props.client.query({
      query: GET_FILE,
      variables: { code, user },
    })
    const store = result.data.store
    console.log(store)
    this.setState({ isLoading: false })
    window.open(result.data.store.user.midSchCompletionExamFileUrl)
  }
}

BasicButton.propTypes = {
  user: PropTypes.number,
  code: PropTypes.number,
}

export default withApollo(BasicButton)

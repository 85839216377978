import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import Box from '@material-ui/core/Box'
// import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
// import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { renderWeeklyStatus } from '../utils/try-convert'
import PrintButton from '../utils/print'

const GET_WEEKLY = gql`
  query store($code: ID!, $id: ID!) {
    store(code: $code){
      code
      user(id: $id) {
        id
        gradeCode
        weeksForWeekly {
          gradeCode
          fiscalYear
          weeks {
            startedOn
            endedOn
            week
          }
        }
        weeksForWeeklyForAdmin {
          gradeCode
          fiscalYear
          weeks {
            startedOn
            endedOn
            week
          }
        }
        midSchWeeklyTasks {
          id
          midSchNormalSubjectId
          week
          gradeCode
          examinationStatus
          # midSchWeeklyLessons {
          #   id
          #   understandingLevel
          # }
        }
      }
    }
  }
`

class WeeklyCheck extends Component {
  state = {
    store: [],
    code: this.props.code,
    id: this.props.id
  }

  render () {
    const { code, id } = this.props

    return (
      <Query query={GET_WEEKLY} variables={{ code, id }}>
        {({ data, loading, error }) => {
          if (loading) return <p>毎週チェック結果を取得中...</p>
          if (error) return <p>エラーが発生しました。システム管理者にお問い合せください。</p>

          console.log(data)

          const sortWeekly = function (a, b) {
            if (a.gradeCode < b.gradeCode) return 1
            if (a.gradeCode > b.gradeCode) return -1
            if (a.week < b.week) return 1
            if (a.week > b.week) return -1
            if (a.midSchNormalSubjectId < b.midSchNormalSubjectId) return -1
            if (a.midSchNormalSubjectId > b.midSchNormalSubjectId) return 1
          }

          const weeksList = data.store.user.weeksForWeeklyForAdmin
          const renderWeeks = function (gradeCode) {
            switch (gradeCode) {
              case '16': return weeksList[3]
                /* eslint-disable no-unreachable */
                break
                /* eslint-enable no-unreachable */
              case '21': return weeksList[2]
                /* eslint-disable no-unreachable */
                break
                /* eslint-enable no-unreachable */
              case '22': return weeksList[1]
                /* eslint-disable no-unreachable */
                break
                /* eslint-enable no-unreachable */
              case '23': return weeksList[0]
                /* eslint-disable no-unreachable */
                break
                /* eslint-enable no-unreachable */
              default: return ''
            }
          }

          const findDate = function (gradeCode, weekValue) {
            const date = renderWeeks(gradeCode).weeks.find(week => week.week === weekValue)
            const dateObj = Object.assign({}, date)
            const { startedOn, endedOn } = dateObj
            if (startedOn && endedOn) {
              return <span>{format(startedOn, 'YYYY年MMMDo', {locale: ja})} 〜 {format(endedOn, 'YYYY年MMMDo', {locale: ja})}</span>
            } else {
              return <span>&nbsp;</span>
            }
          }

          const allResult = data.store.user.midSchWeeklyTasks.sort(sortWeekly)

          const filterWeek = allResult.reduce((result, current) => {
            if (data.store.user.weeksForWeeklyForAdmin[0] && data.store.user.weeksForWeeklyForAdmin[0].weeks[0]) {
              if (current.gradeCode === '23' && current.week <= data.store.user.weeksForWeeklyForAdmin[0].weeks[0].week) {
                result.push(current)
              }
            }
            if (data.store.user.weeksForWeeklyForAdmin[1] && data.store.user.weeksForWeeklyForAdmin[1].weeks[0]) {
              if (current.gradeCode === '22' && current.week <= data.store.user.weeksForWeeklyForAdmin[1].weeks[0].week) {
                result.push(current)
              }
            }
            if (data.store.user.weeksForWeeklyForAdmin[2] && data.store.user.weeksForWeeklyForAdmin[2].weeks[0]) {
              if (current.gradeCode === '21' && current.week <= data.store.user.weeksForWeeklyForAdmin[2].weeks[0].week) {
                result.push(current)
              }
            }
            if (data.store.user.weeksForWeeklyForAdmin[3] && data.store.user.weeksForWeeklyForAdmin[3].weeks[0]) {
              if (current.gradeCode === '16' && current.week <= data.store.user.weeksForWeeklyForAdmin[3].weeks[0].week) {
                result.push(current)
              }
            }
            return result
          }, [])

          console.log(allResult)
          console.log(filterWeek)

          const groupGrade = filterWeek.reduce((result, current) => {
            const element = result.find((p) => {
              return p.gradeCode === current.gradeCode
            })
            if (element) {
              element.weeks.push({
                week: current.week,
                id: current.midSchNormalSubjectId,
                status: current.examinationStatus
              })
            } else {
              result.push({
                gradeCode: current.gradeCode,
                weeks: [
                  {
                    week: current.week,
                    id: current.midSchNormalSubjectId,
                    status: current.examinationStatus
                  }
                ]
              });
            }
            return result

          },[])

          console.log(groupGrade)

          const groupWeek = groupGrade.map(grade => {
            const gradeCode = grade.gradeCode
            const weeks = grade.weeks.reduce((result, current) => {
              const element = result.find((p) => {
                return p.week === current.week
              })
              if (element) {
                element.subjects.push({
                  id: current.id,
                  status: current.status
                })
              } else {
                result.push({
                  gradeCode: gradeCode,
                  week: current.week,
                  subjects: [
                    {
                      id: current.id,
                      status: current.status
                    }
                  ]
                });
              }
              return result

            },[])

            return weeks

          })

          console.log(groupWeek)

          const selectedSubject = (result, subjectId) => {
            if (result.filter(x => x.id === subjectId).length) {
              return true
            } else {
              return false
            }
          }

          const subjectStatus = (result, subjectId) => {
            const subject = result.find(x => x.id === subjectId)
            return subject.status
          }

          return (
            <>
              <PrintButton />
              <Box my={5} mx={2}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">実施週</TableCell>
                        <TableCell align="center">英語</TableCell>
                        <TableCell align="center">数学</TableCell>
                        <TableCell align="center">理科</TableCell>
                        <TableCell align="center">地理</TableCell>
                        <TableCell align="center">歴史</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {groupWeek.map(grade => (
                        grade.map(result => (
                          <TableRow>
                            <TableCell align="left" component="th" scope="row" width="200">
                              {findDate(result.gradeCode, result.week)}
                            </TableCell>
                            <TableCell align="center">
                              {selectedSubject(result.subjects, '1') ? `${renderWeeklyStatus(subjectStatus(result.subjects, '1'))}` : `${renderWeeklyStatus(0)}`}
                            </TableCell>
                            <TableCell align="center">
                              {selectedSubject(result.subjects, '2') ? `${renderWeeklyStatus(subjectStatus(result.subjects, '2'))}` : `${renderWeeklyStatus(0)}`}
                            </TableCell>
                            <TableCell align="center">
                              {selectedSubject(result.subjects, '3') ? `${renderWeeklyStatus(subjectStatus(result.subjects, '3'))}` : `${renderWeeklyStatus(0)}`}
                            </TableCell>
                            <TableCell align="center">
                              {selectedSubject(result.subjects, '4') ? `${renderWeeklyStatus(subjectStatus(result.subjects, '4'))}` : `${renderWeeklyStatus(0)}`}
                            </TableCell>
                            <TableCell align="center">
                              {selectedSubject(result.subjects, '5') ? `${renderWeeklyStatus(subjectStatus(result.subjects, '5'))}` : `${renderWeeklyStatus(0)}`}
                            </TableCell>
                          </TableRow>
                        ))
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
            </>
          )
        }}
      </Query>
    )
  }

}

WeeklyCheck.propTypes = {
  id: PropTypes.number.isRequired,
  user: PropTypes.number.isRequired,
  code: PropTypes.number.isRequired,
}

export default WeeklyCheck

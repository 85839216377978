import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import PrintButton from '../components/utils/print'

const GET_QR = gql`
  query user($userCode: ID!) {
    user(userCode: $userCode) {
      qr
      __typename
    }
  }
`

class QRView extends Component {
  state = {
    user: [],
    props: this.props,
    code: this.props.code,
    userCode: this.props.userCode,
    firstName: this.props.firstName,
    lastName: this.props.lastName,
  }

  render () {
    const { code, userCode, firstName, lastName } = this.state.props.match.params
    // console.log(this.state.props)

    return (
      <Query query={GET_QR} variables={{ code, userCode }}>
        {({ data, loading, error }) => {
          if (loading) return <p>QRコードを取得中</p>
          if (error) return <p>エラーが発生しました。システム管理者にお問い合せください。</p>

          return (
            <>
              <PrintButton />
              <Container maxWidth="sm">
                <Box my={4}>
                <Typography variant="h5" component="h1" gutterBottom>
                  <span>生徒名: {decodeURIComponent(lastName)} {decodeURIComponent(firstName)}</span>
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  <span>生徒コード: {userCode}</span>
                </Typography>
                </Box>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Box my={4}>
                    <img src={data.user.qr} alt="" />
                  </Box>
                </Grid>
              </Container>
            </>
          )
        }}
      </Query>
    )
  }

}

QRView.propTypes = {
  code: PropTypes.number.isRequired,
  userCode: PropTypes.number.isRequired,
}

export default QRView

import React, { Component } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

class PrintButton extends Component {
  render() {
    return (
      <Grid container>
        <Grid item xs={12} justify="flex-end" alignItems="flex-start">
          <Box mt={2} mx={2} align="right">
            <Button variant="outlined" size="small" onClick={() => window.print()}>
              <Icon>print</Icon>&nbsp;印刷する
            </Button>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

export default PrintButton
